<template>
  <div style="max-width: 1000px">
    <v-row class="mt-4">
      <v-col cols="12" md="9" xs="12">
        <h1 style="padding-bottom: 0px">
          Über die EisbaumTabelle <sup>2024</sup>
        </h1>

        <h2><span style="color: #d84315">Unsere Idee</span></h2>
        Meine erste Tierarztrechnung: 403,82 €. Da stellt man sich die Frage:
        “Kann ich mir diesen Hund überhaupt leisten?” Schnell war klar, “ich
        muss einen Weg finden” da meine Familie und ich den kleinen Hundewelpen
        schon fest in unser Herz geschlossen hatten. Dann begann die Recherche.
        Unzählige Tarife, unterschiedliche Versicherungsbedingungen und hunderte
        Seiten an AVBs machten die Auswahl der richtigen Versicherung besonders
        schwierig. Die Idee war geboren: Eine Vergleichswert, der alle
        Tierversicherungen miteinander vergleichbar macht und so die Auswahl der
        richtigen Tierversicherung wesentlich vereinfacht. Die Umsetzung: 18
        Monate Entwicklungsarbeit und unzählige Abstimmungen mit Spezialisten
        aus der Tiermedizin und der Versicherungsbranche später ist
        EisbaumTabelle.de nun online und hilft Tierbesitzern deutschlandweit die
        richtige Tierkrankenversicherung zu finden.
        <br />
        <br />
        <h2><span style="color: #d84315">Unser Anspruch</span></h2>
        Gesundheit ist das höchste Gut. Auch bei Tieren! Tierbesitzer sollten
        sich keine Sorgen darüber machen müssen, ob sie sich die nächste
        Behandlung für ihr Tier noch leisten können. Aus diesem Grund sollte
        jedes Tier so versichert sein, dass es die Behandlung bekommt die es
        braucht.
        <br />
        <br />
        <h2><span style="color: #d84315">Unsere Beitrag</span></h2>
        Die richtige Tierkrankenversicherung ist entscheidend! Wir helfen Ihnen
        sie zu finden. EisbaumTabelle hat ein einzigartiges und unabhängiges
        Bewertungssystem <link /> entwickelt, welches Tierkrankenversicherungen
        miteinander vergleichbar macht. Über unseren Online Rechner stellen wir
        diese Informationen klar und einfach zur Verfügung und helfen Ihnen so
        die richtige Krankenversicherung für Ihr Tier zu finden. Mit unserem
        Leistungsfall-Service unterstützen wir Sie im Leistungsfall und setzen
        Ihre berechtigten Ansprüche für Sie durch.
      </v-col>

      <v-col cols="12" md="12" xs="12">
        <h3>Mozart | Unser Bester</h3>
        <img width="180" src="/images/mozi.jpg"
      /></v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: "index-ueber",
    components: {},

    data: function() {
      return {
        updateSeo: {
          seo_type: 2,
          seo_object_id: 1,
          seo_title: "",
        },
        tarife: false,
        select: {
          type: "hunde",
          preise: "ja",
          number: "array",
        },
      };
    },
    created() {
      document.dispatchEvent(new Event("x-app-rendered"));
      this.getTarife();
      this.setTitle();
      //this.testGuest();
    },

    methods: {
      saveReferrer() {
        var string = document.referrer,
          substring = ".google";
        var isGoogle = string.indexOf(substring) !== -1;
        if (isGoogle) {
          this.updateSeo.seo_title = document.title;
          this.$store.dispatch("saveReferrer", this.updateSeo);
        }
      },
      testGuest() {
        this.$log.debug("trachCheck app insert");
        this.$store.dispatch("testGuest").then((resp) => {
          if (resp.data.guest) {
            this.guest = resp.data.guest;
          }
        });
      },
      setTitle() {
        this.$log.debug("title");
        document.title = "Alles Wissenswerte zur EisbaumTabelle im Überblick";
        let metadesc =
          "EisbaumTabelle - Unsere Idee, unser Anspruch, unser Beitrag";
        this.saveReferrer();
        // document
        //   .querySelector('meta[name="description"]')
        //   .setAttribute("content", this.metadesc);
      },
      getTarife() {
        this.$store
          .dispatch("getTarife", this.select)
          .then((resp) => {
            if (resp.data.tarife) {
              this.tarife = resp.data.tarife;
              document.dispatchEvent(new Event("x-app-rendered"));
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
    },
  };
</script>
